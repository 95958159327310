import React, { useContext } from 'react';
import { SecondaryButton, Icon } from '@hz-design-system/web-ui';
import { Trans, I18nContext } from '@ecg-marktplaats/js-react-i18n';

import { GA_TRACK_EVENTS_ACTIONS } from '../../../utils/constants';
import EnvironmentContext from '../../../contexts/EnvironmentContext';

import './InspectionService.scss';
import Classes from './InspectionService.scss';

const InspectionService = () => {
  const { t } = useContext(I18nContext);
  const { inspectionServiceImageUrl } = useContext(EnvironmentContext);

  return (
    <div className={`block-wrapper ${Classes.root}`}>
      <div className={Classes.content}>
        <Trans i18nKey="inspectionService.title" tagName="p" className={Classes.title} />
        <span className={Classes.usp}>
          <Icon name="car_body" color="signal-action-default" className={Classes.icon} />
          <Trans i18nKey="inspectionService.usp1" tagName="span" />
        </span>
        <span className={Classes.usp}>
          <Icon name="car_history" color="signal-action-default" className={Classes.icon} />
          <Trans i18nKey="inspectionService.usp2" tagName="span" />
        </span>
        <span className={Classes.usp}>
          <Icon name="clock" color="signal-action-default" className={Classes.icon} />
          <Trans i18nKey="inspectionService.usp3" tagName="span" />
        </span>
        <Trans i18nKey="inspectionService.promo" tagName="p" className={Classes.promo} />
        <SecondaryButton
          as="a"
          data-testid="OKNBannerButton"
          rel="nofollow noopener"
          className={Classes.button}
          href="https://www.occasionkeuringnederland.nl/aankoopkeuring-marktplaats/"
          data-ga-track-event={GA_TRACK_EVENTS_ACTIONS.OKNBannerClick}
        >
          <Trans tagName="span" i18nKey="inspectionService.buttonLabel" />
        </SecondaryButton>
      </div>
      <img src={inspectionServiceImageUrl} alt={t('inspectionService.imageAlt')} className={Classes.image} />
    </div>
  );
};

export default InspectionService;
