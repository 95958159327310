import React from 'react';
import { ButtonIcon, Icon, Link, SecondaryButton } from '@hz-design-system/web-ui';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import Classes from './SellerInfo.scss';
import { createShowAsqHash } from '../SellerContactOptions/utils';
import { AsqHashParams } from '../SellerContactOptions/consts';
import { TAsqMessageType } from '../SellerContactOptions/types';
import { trackGAEvent } from '../../../../utils/gaTracking';
import { GA_TRACK_EVENTS_ACTIONS } from '../../../../utils/constants';

const SellerTestDriveLink = ({ asButton }: { asButton?: boolean }) => {
  const { t } = useI18nContext();

  const url = createShowAsqHash(
    AsqHashParams.MESSAGE_TYPE.VALUES.TEST_DRIVE as TAsqMessageType,
    AsqHashParams.PREVIOUS_ACTION.VALUES.TEST_DRIVE,
  );

  const fireGAOnClick = () => {
    trackGAEvent(
      GA_TRACK_EVENTS_ACTIONS.R2SEmailBegin,
      `${AsqHashParams.MESSAGE_TYPE.KEY}=${AsqHashParams.MESSAGE_TYPE.VALUES.TEST_DRIVE}`,
    );
  };

  return (
    <>
      {asButton && (
        <SecondaryButton className="SellerContactOptions-button" as="a" onClick={fireGAOnClick} href={url}>
          <ButtonIcon name="calendar" isStartIcon={false} />
          <Trans tagName="span" i18nKey="sellerInfo.testDrive.link" />
        </SecondaryButton>
      )}
      {!asButton && (
        <div className={Classes.rowWithIcon}>
          <Link href={url} onClick={fireGAOnClick}>
            <Icon name="calendar" color="signal-link-default" className={Classes.icon} />
            {t('sellerInfo.testDrive.link')}
          </Link>
        </div>
      )}
    </>
  );
};

export default SellerTestDriveLink;
